button[class*="MuiButton-root"]:hover {
  color: white;
  background: #a90004 !important;
}
button[class*="MuiButton-root"].button-link:hover {
  background: gray !important;
}
.MuiButton-outlinedPrimary {
  color: black !important;
}
.MuiButton-outlinedPrimary:hover {
  color: white !important;
}
.MuiFab-primary {
  background-color: #a90004 !important;
}
.MuiInputLabel-root {
  background-color: white;
}
.MuiButton-root:hover {
  color: white;
  background: #a90004 !important;
}
.MuiInput-root input[type="date"] {
  margin-top: 1em !important;
}
.MuiCircularProgress-colorPrimary {
  color: #a90004 !important;
}
.MuiPaper-root {
  padding: 1em;
}
button .material-icons {
  padding-right: 0.2em;
}
.Mui-checked {
  color: #a90004 !important;
}
.MuiTextField-root {
  width: 100%;
}
.material-icons {
  font-size: 1.2em;
  margin-right: 0.5em;
}
header .logo {
  font-size: 2em;
  margin-left: 2em;
}
@media (max-width: 1000px) {
  header .logo {
    font-size: 1.2em;
    margin-top: 0.4em;
    margin-left: 2.5em;
  }
}
header .logo-red {
  color: #ffffff;
  font-weight: bold;
  text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
  margin-top: 0.7em;
}
header .logo-icon {
  margin-top: 0.7em;
}
header .logo-icon img {
  height: 1.4em;
  border-radius: 10em;
}
header .logo-white {
  color: white;
  margin-left: 0.2em;
}
header .product-title {
  color: #ffffff;
  margin-left: 1em;
  margin-top: 0.7em;
}
@media (max-width: 1000px) {
  header .product-title {
    margin-top: 0.1em;
    width: 10em;
  }
}
header .wrapper {
  justify-content: space-between;
  width: 100%;
  background-color: #3f3f3f;
  height: 6em;
}
@media (max-width: 1000px) {
  header .wrapper {
    height: 4em;
  }
}
header .link-back-login {
  padding-left: 2em;
  padding-top: 1.8em;
}
@media (max-width: 1000px) {
  header .link-back-login {
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1.1em;
  }
}
header .back-login {
  font-size: 2em;
  color: white;
}
header .need-help {
  color: white;
  margin-top: 2.3em;
  margin-right: 1em;
  cursor: pointer;
}
@media (max-width: 1000px) {
  header .need-help {
    margin-top: 1.3em;
    display: none;
  }
}
header .need-help:hover {
  color: #a90004;
}
#toast {
  position: fixed;
  top: 7em;
  width: 30em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.95;
  z-index: 99;
}
#toast .title {
  font-size: 1.2em;
  font-weight: bold;
  display: inline;
  margin-right: 1em;
}
#toast .toast {
  padding: 1em;
}
#toast .success {
  background-color: #61b961;
}
#toast .error {
  background-color: #ce696b;
}
#toast .message {
  display: inline;
}
#toast .close-button {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  opacity: 0.6;
}
#toast .fa {
  color: white;
  font-size: 1.2em;
}
#app-error {
  display: inline-block;
  width: 100%;
}
#app-error .error-message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 10em;
  width: 30%;
  background-color: #ce696b;
  padding: 2em;
}
#loader {
  display: inline-block;
  width: 100%;
}
#loader .load-icon {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 2em;
  padding: 2em;
  font-size: 4em;
}
#progress {
  margin: 0 1em 1em 1em;
}
#progress .check-circle {
  background: white;
  border-radius: 3em;
  position: relative;
  height: 0;
  width: 0;
  padding: 0.6em;
}
#progress .fa-check-circle {
  border-radius: 1em;
  position: absolute;
  top: -0.2em;
  left: 0;
  font-size: 1.5em;
}
#progress .check-circle.complete-check {
  background: white;
  border-radius: 3em;
  position: relative;
  height: 0.5em;
  width: 0.9em;
  padding: 0.6em;
}
#progress .fa-check-circle.complete-check {
  color: green;
  font-size: 2.5em;
}
#home .review,
#home .download {
  margin-left: 2em;
}
#home .actions {
  justify-content: flex-start;
}
#home .actions .flex-col {
  margin-right: 1em;
}
#home .flex-col {
  margin-bottom: 1em;
}
#home .begin {
  width: 6em;
}
#home .complete-check {
  color: green;
  background-color: white;
  border-radius: 1em;
  font-size: 1em;
  margin-right: 0.2em;
  padding: 0;
}
#title h1 {
  text-align: center;
}
#title .actions {
  justify-content: center;
}
#page .page-content {
  justify-content: center;
}
#page .image-container {
  max-height: 35em;
  max-width: 100em;
  width: 50%;
}
@media (max-width: 1000px) {
  #page .image-container {
    width: 100%;
  }
}
#page .image {
  width: 100%;
  max-height: 35em;
  max-width: 100em;
}
#page section p {
  padding-top: 1em !important;
}
#page .page-text-container {
  width: 40%;
  min-width: 20em;
  margin-right: 2em;
  margin-bottom: 2em;
}
@media (max-width: 1000px) {
  #page .page-text-container {
    width: 100%;
    margin-right: 0;
  }
}
#page .page-text {
  font-size: 1.2em;
  max-width: 100em;
}
#page .actions {
  justify-content: flex-end;
}
#page .actions .back {
  margin-right: 2em;
}
#question .question-content {
  justify-content: center;
}
#question .image-container {
  max-height: 35em;
  max-width: 100em;
  width: 50%;
}
@media (max-width: 1000px) {
  #question .image-container {
    width: 100%;
  }
}
#question .image {
  width: 100%;
  max-height: 35em;
  max-width: 100em;
}
#question .wrong-answer {
  color: #a90004;
}
#question .correct-answer {
  color: green;
}
#question .answer-response {
  margin-bottom: 1em;
}
#question .answer-area {
  background-color: white;
  margin-bottom: 2em;
  padding: 1em;
  margin-top: 1em;
}
#question .answer-text {
  padding-left: 1em;
}
#question .question-container {
  width: 40%;
  margin-right: 2em;
  margin-bottom: 2em;
}
@media (max-width: 1000px) {
  #question .question-container {
    width: 100%;
    margin-right: 0;
  }
}
#question .question {
  font-size: 1.2em;
  color: black;
  padding-top: 1em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
#question .actions {
  justify-content: flex-end;
}
#question .actions .back {
  margin-right: 2em;
}
#thankyou .message-box-container {
  height: 400px;
  margin: auto;
  margin-bottom: 8em;
}
#thankyou .headline {
  padding-top: 5em;
  font-size: 1.5em;
  text-align: center;
}
#thankyou .logout-container {
  margin-top: 1em;
  justify-content: center;
}
#thankyou .download-container {
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 24em;
}
#thankyou .btn-logout {
  border-radius: 10px;
  background: #ffffff;
  color: black;
  margin: auto;
  border-style: none;
  width: 100%;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
}
#video {
  max-width: 55em;
}
#video .page-content {
  justify-content: center;
}
#video .video-container {
  max-height: 35em;
  max-width: 100em;
  width: 50%;
}
@media (max-width: 1000px) {
  #video .video-container {
    width: 100%;
  }
}
#video .video {
  width: 100%;
  max-height: 35em;
  max-width: 100em;
}
#video section p {
  padding-top: 1em !important;
}
#video .video-text-container {
  width: 100%;
  min-width: 20em;
  margin-right: 2em;
  margin-bottom: 2em;
}
@media (max-width: 1000px) {
  #video .video-text-container {
    width: 100%;
    margin-right: 0;
  }
}
#video .video-complete-text {
  font-size: 1.2em;
  max-width: 100em;
  text-align: center;
}
#video .actions {
  justify-content: flex-end;
}
#video .actions .back {
  margin-right: 2em;
}
#video .pause-play {
  margin-left: 1em;
}
.vimeo-video {
  width: 100%;
}
.vimeo-video .vimeo-loading {
  display: none;
}
.vimeo-video .vimeo-embed {
  width: 100%;
}
.vimeo-video iframe {
  min-width: 75em;
  min-height: 30em;
}
@media (max-width: 1000px) {
  .vimeo-video iframe {
    min-width: 20em;
    min-height: 20em;
  }
}
@media (max-width: 1600px) {
  .vimeo-video iframe {
    min-width: 56em;
    margin-top: 3em;
  }
}
#app {
  background: #f5f5f5;
}
a:hover,
a:focus {
  text-decoration: none !important;
}
html {
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.app {
  min-height: 100%;
  width: 100%;
}
.content {
  min-height: 50em;
  margin-top: 2em;
  padding-left: 3em;
  padding-bottom: 5em;
  background: #f5f5f5;
  border: 0px solid #000000;
  padding-right: 3em;
  height: 100%;
}
@media (max-width: 1000px) {
  .content {
    margin-left: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 2em;
  }
}
h1 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
  font-size: 3em;
}
h2 {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  color: black;
}
p {
  padding: 0.25em 0 1em 0;
  color: black;
  line-height: 1.5em;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
select.input-field {
  height: 2.5em;
  width: 100%;
}
.input-field {
  margin-top: 1em;
  margin-bottom: 1em;
  box-sizing: content-box;
  padding: 1em 0 1em 0.5em;
  height: 1em;
  width: 100%;
  color: black;
}
.btn-logout:hover {
  text-decoration: none;
  color: white;
  background: #a90004;
}
.btn-logout {
  border: solid black 0.1em;
  padding: 0.5em 1em;
  color: black;
  margin: auto;
  width: 75px;
  text-align: center;
  padding: 0.5em;
  cursor: pointer;
  text-decoration: none;
  background: #ffffff;
  float: right;
  margin-top: 1.8em;
  margin-right: 3em;
}
@media only screen and (max-width: 1000px) {
  .btn-logout {
    display: none;
  }
}
.complete-message {
  margin: auto;
  color: black;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.message-box {
  width: 325px;
  margin: auto;
  padding: 2em;
  text-align: center;
}
.message-holder {
  width: 260px;
  padding: 0;
  margin: auto;
}
li {
  text-decoration: none;
}
li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.successMessage {
  font-weight: bold;
  color: #ffffff;
}
.errorMessage {
  font-weight: bold;
  color: #ffffff;
}
label {
  margin-right: 0.5em;
}
