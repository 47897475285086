@import "./color";
@import "./Material";
@import "./Header";
@import "./Message";
@import "./AppError";
@import "./Loader";
@import "./Progress";
@import "./Home";
@import "./Title";
@import "./Page";
@import "./Question";
@import "./ThankYou";
@import "./Video";
 
#app{
  background: @frontground;
}

a:hover, a:focus {
  text-decoration: none !important;
}


html
{
  min-height: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.flex {
  display: flex
}

.flex-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column
}

.flex-center {
  display: flex;
  justify-content: center;
}

.app {
  min-height: 100%;
  width: 100%;
}

  .content {
    min-height: 50em;
    margin-top: 2em;
    padding-left: 3em;
    padding-bottom: 5em;
    background: @frontground;
    border: 0px solid #000000;
    padding-right: 3em;
    height: 100%;

    @media (max-width : 1000px) {
      margin-left: 0;
      padding-left: 1em;
      padding-right: 1em;
      padding-bottom: 2em;
    }

  }

  h1 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
   font-size: 3em;
  }

  h2 {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   font-weight: 300;
   color: @textcolor;
  }

  p {
    padding: .25em 0 1em 0;
    color: @textcolor;
    line-height: 1.5em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 

  }

  select.input-field{
    height: 2.5em;
    width: 100%;
  }

  .input-field {
    margin-top: 1em;
    margin-bottom: 1em;
    box-sizing: content-box;
    padding: 1em 0 1em .5em;
    height: 1em;
    width: 100%;
    color: black;
  }

  .btn-logout:hover{
    text-decoration: none;
    color: white;
    background: @buttonHoverBackground;
  }

  .btn-logout {
    border: solid black 0.1em;
    padding: .5em 1em;
    color: black;
    margin: auto;
    width: 75px;
    text-align: center;
    padding: .5em;
    cursor: pointer;
    text-decoration: none;
    background: @headlines;
    float: right;
    margin-top: 1.8em;
    margin-right: 3em;

    @media only screen and (max-width : 1000px) {
      display: none;
    }
  }

  .complete-message {
    margin: auto;
    color: @textcolor;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  }

  .message-box {
    width: 325px;
    margin: auto;
    padding: 2em;
    text-align: center;
  } 

  .message-holder {
    width: 260px;
    padding: 0;
    margin: auto;
  }

  li {
    text-decoration: none;
  }

  li a {
      display: block;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
  }
  
  .successMessage{
    font-weight: bold;
    color: @accent;
  }

  .errorMessage{
    font-weight: bold;
    color: @headlines;
  }

  label{
    margin-right: .5em;
  }
  
