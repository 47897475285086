#home{
  .review, .download{
      margin-left: 2em;
  }

  .actions{
    justify-content: flex-start;

    .flex-col{
      margin-right: 1em;
    }
  }

  .flex-col{
    margin-bottom: 1em;
  }

  .begin{
    width: 6em;
  }

  .complete-check {
    color: green;
    background-color: white;
    border-radius: 1em;
    font-size: 1em;
    margin-right: 0.2em;
    padding: 0;
}
}
