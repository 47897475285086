header {
  .logo {
    font-size: 2em;
    margin-left: 2em;

    @media (max-width : 1000px) {
      font-size: 1.2em;
      margin-top: .4em;
      margin-left: 2.5em;
    }
  }
  
  .logo-red{
    color: @accent;
    font-weight: bold;
    text-shadow: rgba(0, 0, 0, 0.4) 0px 4px 5px;
    margin-top: .7em;
  }

  .logo-icon {
    margin-top: .7em;
    
    img {
      height: 1.4em;
      border-radius: 10em;
    }
  }
  
  .logo-white{
    color: white;
    margin-left: .2em;
  }

  .product-title{
    color: @headlines;
    margin-left: 1em;
    margin-top: .7em;

    @media (max-width : 1000px) {
      margin-top: .1em;
      width: 10em;      
    }
  }

  .wrapper{
    justify-content: space-between;
    width: 100%;
    background-color: @background;
    height: 6em;

    @media (max-width : 1000px) {
      height: 4em;
    }
  }

  .link-back-login {
    padding-left: 2em;
    padding-top: 1.8em;
    
    @media (max-width : 1000px) {
      font-size: 1em;
      padding-left: 1em;
      padding-right: 1em;
      padding-top: 1.1em;
    }
  }

  .back-login{
    font-size: 2em;
    color: white;
  }

  .need-help{
    color: white;
    margin-top: 2.3em;
    margin-right: 1em;
    cursor: pointer;

    @media (max-width : 1000px) {
      margin-top: 1.3em;
      display: none;
    }
  }

  .need-help:hover{
    color: @buttonHoverBackground;
  }

}