#progress{
    margin: 0 1em 1em 1em;

    .check-circle{
        background: white;
        border-radius: 3em;
        position: relative;
        height: 0;
        width: 0;
        padding: 0.6em;
    }

    .fa-check-circle{
        border-radius: 1em;
        position: absolute;
        top: -0.2em;
        left: 0;
        font-size: 1.5em;
    }


    .check-circle.complete-check{
        background: white;
        border-radius: 3em;
        position: relative;
        height: 0.5em;
        width: 0.9em;
        padding: 0.6em;
    }

    .fa-check-circle.complete-check {
        color: green;
        font-size: 2.5em;
    }

    
}