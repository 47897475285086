 #thankyou {
  
  
  .message-box-container {
    height:400px;
    margin: auto; 
    margin-bottom: 8em;   
  }

  .headline {
    padding-top: 5em;
    font-size: 1.5em;
    text-align: center;
  }

  .logout-container{
    margin-top: 1em;
   justify-content: center;
  }

  .download-container{
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    width: 24em;
  }

  .btn-logout {
    border-radius: 10px;
    background: @headlines;
    color: black;
    margin: auto;
    border-style: none;
    width: 100%;
    text-align: center;
    padding: .5em;
    cursor: pointer;
  }


}