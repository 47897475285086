#page {
  .page-content{
    justify-content: center;
  }

  .image-container{
    max-height: 35em;
    max-width: 100em;
    width: 50%;

    @media (max-width : 1000px) {
      width: 100%;
    }
  }

  .image{
    width: 100%;
    max-height: 35em;
    max-width: 100em;
  }

  section p {
   padding-top: 1em !important;
  }

  .page-text-container{
    width: 40%;
    min-width: 20em;
    margin-right: 2em;
    margin-bottom: 2em;

    @media (max-width : 1000px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .page-text{
    font-size: 1.2em;
    max-width: 100em;
  }

  .actions{
    justify-content: flex-end;
    
    .back{
      margin-right: 2em;
    }
  }

}