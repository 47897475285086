#video {
  max-width: 55em;

    .page-content{
      justify-content: center;
    }
  
    .video-container{
      max-height: 35em;
      max-width: 100em;
      width: 50%;
  
      @media (max-width : 1000px) {
        width: 100%;
      }
    }
  
    .video{
      width: 100%;
      max-height: 35em;
      max-width: 100em;
    }
  
    section p {
     padding-top: 1em !important;
    }
  
    .video-text-container{
      width: 100%;
      min-width: 20em;
      margin-right: 2em;
      margin-bottom: 2em;
  
      @media (max-width : 1000px) {
        width: 100%;
        margin-right: 0;
      }
    }

    .video-complete-text{
      font-size: 1.2em;
      max-width: 100em;
      text-align: center;

    }
   
    .actions{
      justify-content: flex-end;
      
      .back{
        margin-right: 2em;
      }
    }

    .pause-play{
      margin-left: 1em;
    }
  
  }

  .vimeo-video{
    width: 100%;

    .vimeo-loading{
        display: none;
    }

    .vimeo-embed{
        width: 100%;
    }

    iframe {
        min-width: 75em;
        min-height: 30em;

        @media (max-width : 1000px) {
            min-width: 20em;
            min-height: 20em;
          }

          @media (max-width : 1600px) {
            min-width: 56em;
            margin-top: 3em;
          }
    }
}