#question{
  .question-content{
    justify-content: center;
  }

  .image-container{
    max-height: 35em;
    max-width: 100em;
    width: 50%;

    @media (max-width : 1000px) {
      width: 100%;
    }
  }

  .image{
    width: 100%;
    max-height: 35em;
    max-width: 100em;
  }

  .wrong-answer {
    color: @wrongAnswerText;
  }

  .correct-answer {
    color: @correctAnswerText;
    
  }

  .answer-response{
    margin-bottom: 1em;
  }


  .answer-area {
      background-color:white;
      margin-bottom: 2em;
      padding: 1em;
      margin-top: 1em;
  }

  .answer-text{
    padding-left: 1em;
  }

  .question-container{
    width: 40%;
    margin-right: 2em;
    margin-bottom: 2em;

    @media (max-width : 1000px) {
      width: 100%;
      margin-right: 0;
    }
  }

  .question{
    font-size: 1.2em;

    color: black;
    padding-top: 1em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;   
  }

  .actions{
    justify-content: flex-end;
    
    .back{
      margin-right: 2em;
    }
  }


}
